article {
    text-align: center;
    background-color: var(--bg-color);
}

.blog-anim-header {
    padding: 14.5rem 0px 12rem 0px;
}

.blog-header {
    padding: 6rem 10vw 3rem 10vw;
    background-color: var(--background);
    text-align: left;
}

.blog-tags {
    display: flex;
    padding-bottom: 1rem;
    padding-top: 0.25rem;
    margin-bottom: auto;
}

.profile {
    display: flex;
}

.profile-img {
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    margin-right: 0.5rem;
    box-shadow: var(--image-shadow);
}

.profile p {
    margin-top: auto;
    margin-bottom: auto;
}

.blog-header h2 {
    font-weight: 700;
    color: var(--text);
}

.profile-id {
    color: var(--text);
    opacity: 0.9;
    font-size: 1.1rem;
    font-weight: 600;
    padding: 0px;
}

.datePretty {
    color: var(--text);
    opacity: 0.9;
    font-size: 0.95rem;
    font-weight: 500;
    padding-left: 0.5rem;
    margin-top: auto;
    margin-bottom: auto;
}

.blog-img {
    width: 100%;
    box-shadow: var(--sharp-shadow);
    border-radius: 0.2rem;
}

.blog-content {
    padding: 2.5rem 26vw 1.5rem 26vw;
    background-color: var(--bg-color);
}

.blog-content p {
    font-family: Georgia, serif;
    color: var(--text);
    text-align: left;
    font-size: 1.3rem;
    line-height: 1.5;
    padding: 0px;
}

.blog-content img {
    box-shadow: var(--image-shadow);
    border-radius: 0.2rem;
}

.blog-content a {
    text-decoration: underline 2px solid;
    color: var(--link) !important;
    font-weight: 500;
}

.blog-content strong {
    font-weight: 600;
}

.blog-content em {
    font-family: 'Inter';
    font-weight: 600;
    font-style: normal;
}

.blog-content h2 {
    font-weight: 700;
    text-align: left;
    color: var(--text);
}

hr {
    border: none;
    overflow: visible;
    text-align: center;
    padding: 1rem;
}

article::after {
    content: ". . . . . . . . ";
    color: var(--text);
}

@media screen and (min-width:700px) {
    .blog-header {
        display: flex;
    }

    .blog-info {
        width: 65%;
        display: flex;
        flex-direction: column;
    }

    .blog-info h2 {
        margin-top: auto;
    }

    .blog-img {
        width: 25%;
        margin-left: auto;
    }

    .blog-content img {
        width: 50%;
    }

    .blog-header h2 {
        font-weight: 700;
        color: var(--text);
        font-size: 2.25rem;
        width: 70%;
    }

    div.blog-content p {
        opacity: 0.95;
    }

    .blog-tags {
        margin-bottom: auto;
    }
}

@media screen and (max-width:700px) {
    .blog-anim-header {
        padding: 14.5rem 0px 12rem 0px;
    }

    .blog-header {
        padding: 5rem 6vw 3rem 6vw;
        background-color: var(--background);
        display: flex;
        flex-direction: column;
    }

    .blog-content {
        padding: 2.5rem 6vw 1.5rem 6vw;
    }

    .blog-content img {
        width: 75%;
        box-shadow: var(--image-shadow);
    }

    .blog-content p {
        font-size: 1.2rem;
    }

    div.blog-content p {
        font-size: 19px;
        opacity: 0.95;
    }
}