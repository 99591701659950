.navbar {
  display: flex;
  height: fit-content;
  padding: 0.75rem 8vw;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  background-color: var(--background);
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
  z-index: 10;
}

.navbar h2 {
  height: fit-content;
  margin: auto 0px;
  padding: 0px;
  display: flex;
  font-weight: 800;
  font-style: italic;
  text-transform: uppercase;
  font-size: 1.5rem;
  color: var(--text);
}

.nav-logo {
  display: flex;
}

.nav-mobile {
  display: block;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--bg-color);
}

.nav-mobile-active {
  display: block;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--bg-color);
}

.menu-container {
  margin: auto;
  display: none;
}

.trigger-menu-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
}

ul {
  list-style: none;
}

.nav {
  position: static;
  margin: auto 0px;
  height: fit-content;
  margin-left: auto;
}

.nav-items {
  display: flex;
}

.nav-item {
  color: var(--text);
  opacity: 0.9;
  font-weight: 600;
  display: flex;
  padding-right: 2rem;
  font-size: 0.9rem;
}

.nav-item button {
  z-index: 3;
  margin: auto;
  font-weight: 700;
  padding: 0.5rem 1rem;
  border: 0px;
  background-color: var(--elements);
  color: var(--white);
  border-radius: 2rem;
  box-shadow: var(--image-shadow);
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.nav-item button:hover {
  background-color: var(--white);
  color: var(--elements);
  box-shadow: var(--image-shadow);
}

.nav-item button:disabled {
  background-color: var(--elements);
  opacity: 0.5;
}

.nav-item button:disabled:hover {
  background-color: var(--elements);
  color: var(--white);
  cursor: not-allowed;
}

.nav-link-btn {
  margin: auto;
  text-decoration: none;
  color: var(--text);
}

.nav-link {
  margin: auto;
  text-decoration: none;
  color: var(--text);
  transition: all 0.3s ease-in-out;
}

.active-link {
  display: flex;
  content: "";
  border-bottom: 2px solid var(--text);
  transition: all 0.3s ease-in-out;
  opacity: 1;
}

.nav-link::after {
  display: flex;
  content: "";
  border-bottom: 2px solid var(--text);
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.nav-link:hover::after {
  display: flex;
  content: "";
  border-bottom: 2px solid var(--text);
  transition: all 0.3s ease-in-out;
  opacity: 1;
}

.trigger-menu-wrapper {
  transition: transform 0.4s;
}

.scroll-down .trigger-menu-wrapper {
  transform: translate3d(0, -100%, 0);
}

.scroll-up .trigger-menu-wrapper {
  transform: none;
}

.scroll-up:not(.menu-open) .trigger-menu-wrapper {
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
  background-color: var(--bg-transparent);
  box-shadow: var(--image-shadow);
}

.menu-btn,
.close-btn {
  filter: var(--invert);
}

@media screen and (min-width: 700px) {
  .nav-item button {
    font-weight: 500;
    letter-spacing: 1px;
  }
}

@media screen and (max-width: 700px) {
  .body-fit {
    height: 100vh;
    overflow: hidden;
  }

  .navbar {
    height: fit-content;
    display: flex;
    padding: 0.75rem 6vw;
    width: 100vw;
  }

  .navbar h2 {
    margin: 0px;
    color: var(--text);
  }

  .nav-logo {
    display: flex;
    margin: auto 0px;
  }

  .nav-items {
    display: none;
  }

  .menu-container {
    display: flex;
    margin: auto;
  }

  .nav-items-mobile {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1.25fr;
    margin-bottom: auto;
    text-align: center;
  }

  .nav-item {
    color: var(--text);
    opacity: 0.9;
    font-weight: 600;
    display: flex;
    padding: 0;
    font-size: 0.9rem;
  }

  .close-btn {
    padding: 5vh 0vw 5vh 85vw;
  }

  .theme-mobile {
    margin-top: 1rem;
    display: flex;
  }

  .theme-mobile-title {
    margin: auto 0px auto auto;
    color: var(--text);
    font-weight: 600;
  }
}