.contact-form {
    padding: 3.25rem 15vw 4rem 15vw;
    background-color: var(--bg-color);
    display: flex;
    flex-direction: column;
}

.contact-form-element {
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    width: 30vw;
    margin: auto;
}

.form-content {
    font-weight: 600;
    color: var(--text);
    text-align: center;
    width: 65%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: .25rem;
}

.form-content-end {
    font-weight: 600;
    color: var(--text);
    text-align: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 0.25rem;
    padding-bottom: 1.25rem;
}

.contact-input {
    border: 2px solid var(--bg-grey);
    opacity: 0.9;
    border-radius: 2.5rem;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    background-color: var(--element-bg);
}

.message-input {
    border-radius: 1rem;
    padding: 0.5rem 1rem !important;
    font-family: 'Inter';
}

input,
select,
textarea {
    color: var(--text);
}

.contact-label {
    color: var(--text);
    opacity: 0.9;
    margin-left: 0.9rem;
    font-weight: 500;
    padding-top: 0.75rem;
    padding-bottom: 0.4rem;
}

.submit-btn {
    font-size: 1rem;
    font-weight: 600;
    background-color: var(--elements);
    color: var(--white);
    padding: 0.75rem 1rem;
    border: 0px;
    margin-top: 2rem;
    border-radius: 2.5rem;
}

.submit-btn:hover {
    opacity: 0.85;
    cursor: pointer;
}

.submit-anim {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    z-index: 10;
    width: 100vw;
    padding: 10rem 0px;
    background-color: var(--elements);
    color: var(--text);
}

.MessageSubmit {
    display: block;
    padding: 4rem 15vw 4rem 15vw;
    background-color: var(--bg-color);
    text-align: center;
}

.MessageSubmit img {
    width: 6rem;
}

.err-img {
    padding: 0.3rem;
}

.MessageSubmit h3 {
    padding: 0.5rem;
    color: var(--text);
}

.btn {
    font-size: 0.9rem;
    font-weight: 600;
    background-color: var(--elements);
    color: var(--white);
    padding: 0.75rem 1rem;
    border: 0px;
    margin-top: 1rem;
    border-radius: 2.5rem;
}

.btn:hover {
    opacity: 0.85;
}

@media screen and(max-width:700px) {
    .contact-header {
        padding: 7rem 6vw 2.5rem 6vw;
        background-color: var(--background);
    }

    .contact-form {
        padding: 2rem 6vw 4rem 6vw;
    }

    .contact-form-element {
        width: 95%;
    }

    .form-content {
        width: 100%;
    }
}