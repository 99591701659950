#themeSwitcher {
  background: var(--image) no-repeat center;
  background-color: var(--text);
  background-size: 75% 75%;
  width: 2rem;
  height: 2rem;
  margin: 0.5rem;
  margin-left: auto;
  border: none;
  border-radius: 50%;
  transition: background-color 0.3s ease-in-out, filter 0.3s ease-in-out;
}

#themeSwitcher:hover,
#themeSwitcher:focus {
  filter: none;
  cursor: pointer;
}

#themeSwitcher.clicked {
  filter: none !important;
}

@media (prefers-color-scheme: light) {
  body {
    --elements: #6219ff;
    --elements-transparent: rgba(98, 26, 255, 0.6);
    --background: #f4f0eb;
    --bg-color: #ffffff;
    --bg-grey: #eaeaea;
    --text: #1c203c;
    --white: #ffffff;
    --link: #1a0dab;
    --image: url("../../assets/icons/light.svg");
    --bg-transparent: rgba(255, 255, 255, 0.7);
    --bg-gradient: linear-gradient(111deg,
        rgba(215, 239, 244, 0),
        rgb(203, 235, 242));
    --image-shadow: -5px 5px 15px rgba(28, 32, 60, 0.1);
    --light-shadow: -5px 5px 15px rgba(28, 32, 60, 0.015);
    --sharp-shadow: -5px 5px 0px rgba(28, 32, 60, 0.025);
    --shadow: 0 2.6px 3.6px rgba(0, 0, 0, 0.021),
      0 7.3px 10px rgba(0, 0, 0, 0.03),
      0 17.5px 24.1px rgba(0, 0, 0, 0.039),
      0 58px 80px rgba(0, 0, 0, 0.06);
    --error: #D50000;
    --success: #00bd5e;
    --invert: invert(0);
  }
}

@media (prefers-color-scheme: dark) {
  body {
    --elements: #0071e3;
    --elements-transparent: rgba(59, 64, 70, 0.6);
    --background: #27292d;
    --bg-color: #1f2023;
    --bg-grey: #313131;
    --text: #ffffff;
    --white: #ffffff;
    --link: #8ab4f8;
    --image: url("../../assets/icons/dark.svg");
    --bg-transparent: rgba(31, 32, 35, 0.7);
    --bg-gradient: linear-gradient(111deg,
        rgba(215, 239, 244, 0),
        rgb(203, 235, 242));
    --image-shadow: -8px 8px 15px rgba(1, 1, 1, 0.1);
    --light-shadow: -5px 5px 15px rgba(28, 32, 60, 0.1);
    --sharp-shadow: 8px 8px 0px rgba(1, 1, 1, 0.1);
    --shadow: 0 2.6px 3.6px rgba(0, 0, 0, 0.021),
      0 7.3px 10px rgba(0, 0, 0, 0.03),
      0 17.5px 24.1px rgba(0, 0, 0, 0.039),
      0 58px 80px rgba(0, 0, 0, 0.06);
    --error: #D50000;
    --success: #00bd5e;
    --invert: invert(1);
  }
}

body.light {
  --elements: #0053DD;
  --elements-transparent: rgba(0, 83, 221, 0.6);
  --background: #f4f0eb;
  --bg-color: #ffffff;
  --bg-grey: #eaeaea;
  --text: #1c203c;
  --white: #ffffff;
  --link: #1a0dab;
  --image: url("../../assets/icons/light.svg");
  --bg-transparent: rgba(255, 255, 255, 0.7);
  --bg-gradient: linear-gradient(111deg,
      rgba(215, 239, 244, 0),
      rgb(203, 235, 242));
  --image-shadow: -5px 5px 15px rgba(28, 32, 60, 0.1);
  --light-shadow: -5px 5px 15px rgba(28, 32, 60, 0.015);
  --sharp-shadow: 7px 7px 0px rgba(1, 1, 1, 0.1);
  --shadow: 0 1.4px 3.6px rgba(0, 0, 0, 0.014),
    0 3.8px 10px rgba(0, 0, 0, 0.02),
    0 9px 24.1px rgba(0, 0, 0, 0.026),
    0 30px 80px rgba(0, 0, 0, 0.04);
  --error: #D50000;
  --success: #00bd5e;
  --gray: #626265;
  --invert: invert(0);
}

body.dark {
  --elements: #0053DD;
  --elements-transparent: rgba(0, 83, 221, 0.6);
  --background: #27292d;
  --bg-color: #1f2023;
  --bg-grey: #313131;
  --text: #ffffff;
  --white: #ffffff;
  --link: #8ab4f8;
  --image: url("../../assets/icons/dark.svg");
  --bg-transparent: rgba(31, 32, 35, 0.7);
  --bg-gradient: linear-gradient(111deg,
      rgba(215, 239, 244, 0),
      rgb(203, 235, 242));
  --image-shadow: 8px 8px 15px rgba(1, 1, 1, 0.1);
  --light-shadow: -5px 5px 15px rgba(28, 32, 60, 0.1);
  --sharp-shadow: 8px 8px 0px rgba(1, 1, 1, 0.1);
  --shadow: 0 1.4px 3.6px rgba(0, 0, 0, 0.014),
    0 3.8px 10px rgba(0, 0, 0, 0.02),
    0 9px 24.1px rgba(0, 0, 0, 0.026),
    0 30px 80px rgba(0, 0, 0, 0.04);
  --error: #D50000;
  --success: #00bd5e;
  --gray: #626265;
  --invert: invert(1);
}

@media screen and (max-width:700px) {
  #themeSwitcher {
    margin: 0.5rem;
    margin-right: auto;
  }
}