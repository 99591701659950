.hero {
    padding: 8rem 8vw 4rem 8vw;
    background-color: var(--background);
    color: var(--text);
    display: flex;
    flex-direction: row;
}

.hero-main {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.hero-title {
    font-size: 2.25rem;
    font-weight: 700;
    margin-top: auto;
}

.hero-content {
    font-size: 1.4rem;
    font-weight: 500;
    padding-top: 0px;
    padding-bottom: 0.5rem;
    padding-right: 1rem;
    opacity: 0.95;
}

.hero-tags-container {
    display: flex;
    margin-top: 0.25rem;
    margin-bottom: 0.75rem;
}

.hero-tag {
    background-color: var(--bg-color);
    box-shadow: var(--light-shadow);
    padding: 0.15rem 0.75rem;
    display: flex;
    width: fit-content;
    height: fit-content;
    border-radius: 0.25rem;
    margin-right: 0.9rem;
}

.hero-tag p {
    font-weight: 700;
    padding: 0.25rem 0rem;
    font-size: 0.9rem;
    color: var(--text);
}

.hero-links:hover {
    color: var(--elements);
}

.hero-form-container {
    width: fit-content;
    margin-bottom: auto;
    margin-top: 0.35rem;
}

.hero-form-disabled {
    width: fit-content;
}

.progress {
    z-index: 7;
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
}

.progress-img {
    width: 4rem;
    margin-left: auto;
    padding: 0.5rem;
}

.progress-text {
    color: var(--white);
    padding-left: 0.5rem;
    display: flex;
    margin-top: auto;
    margin-right: auto;
    margin-bottom: auto;
}

.disabled-warning {
    z-index: 5;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: var(--error);
    opacity: 0.7;
    border-radius: 0.2rem;
}

.hero-form {
    opacity: 0.5;
    filter: blur(1px);
    display: flex;
    background-color: var(--bg-color);
    padding: 0.2rem;
    width: fit-content;
    border-radius: 0.2rem;
}

.hero-email {
    font-size: 1.1rem;
    padding: 0.75rem;
    border: 0px;
    background-color: var(--bg-color);
    border-radius: 0.2rem;
}

.hero-submit {
    height: max-content;
    padding: 1rem 2rem;
    font-weight: 600;
    border: 0px;
    background-color: var(--elements);
    color: var(--white);
    border-radius: 0.2rem;
}

.hero-submit:hover {
    opacity: 0.75;
}

.hero-img {
    display: flex;
    width: 50%;
    float: right;
}

.hero-img img {
    margin-left: auto;
    margin-bottom: auto;
    margin-top: auto;
    margin-right: 3vw;
    width: 45%;
    height: fit-content;
    border-radius: 0.75rem;
    box-shadow: var(--shadow);
}

@media screen and (min-width:700px) {
    .hero-tag {
        margin-right: 1rem;
    }
}

@media screen and (max-width: 700px) {
    .hero {
        padding: 6rem 6vw 2.5rem 6vw;
        display: flex;
        flex-direction: column;
    }

    .hero-main {
        position: relative;
        width: 100%;
    }

    .hero-img {
        display: none;
    }

    .hero-img img {
        display: none;
    }

    .hero-content {
        font-size: 1.2rem;
        padding-bottom: 0rem;
        font-weight: 500;
    }

    .hero-title {
        font-size: 2rem;
        font-weight: 700;
    }

    .hero-form-container {
        display: none;
    }

    .hero-form {
        display: block;
        width: 100%;
    }

    .hero-submit {
        width: 100%;
    }

    .hero-tags-container {
        margin-top: 0.8rem;
        margin-bottom: 0px;
    }
}