@import url('https://fonts.googleapis.com/css?family=Dosis:300,400,500');

.pagenotfound {
    padding: 5rem 8vw 4rem 8vw;
    background-color: var(--bg-color);
    color: var(--text);
    font-family: 'Dosis', sans-serif;
    display: flex;
    flex-direction: column-reverse;
}

.notfound-content {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.notfound-content h1 {
    letter-spacing: 0.5rem;
}

.notfound-content p {
    font-size: 1.2rem;
    font-weight: 400;
}

.notfound-caption {
    font-weight: 600 !important;
    padding-bottom: 2rem;
    letter-spacing: 0.05rem;
}

.notfound-img {
    display: flex;
    flex-direction: column;
    position: relative;
}

.pagenotfound-img {
    margin-left: auto;
    margin-right: auto;
    padding-top: 3rem;
    height: auto;
    width: 30vw;
}

.goback-btn {
    width: fit-content;
    height: fit-content;
    margin: auto;
    margin-top: 1.5rem;
    padding: 0.75rem 1.75rem;
    font-weight: 600;
    border: 0px;
    background-color: var(--elements);
    color: var(--white);
    border-radius: 2rem;
}

// Animations
.goback-btn:hover {
    opacity: 0.85;
    cursor: pointer;
}

@media only screen and (max-width: 700px) {
    .pagenotfound {
        padding: 5rem 6vw 3rem 6vw;
    }

    .pagenotfound-img {
        margin-left: auto;
        margin-right: auto;
        padding-top: 3rem;
        padding-right: 1rem;
        width: 90vw;
    }
}