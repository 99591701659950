.footer {
    padding: 1.5rem 8vw 1.5rem 8vw;
    background-color: var(--background);
    color: var(--text);
    display: flex;
}

.app-icons {
    display: flex;
    flex: 1;
}

.footer-icons {
    width: 1.5rem;
    fill: var(--text);
    transition: fill 0.4s ease, filter 0.4s ease;
}

.footer-icons:hover {
    filter: brightness(150%);
    fill: var(--elements);
}

.margin-left {
    margin-left: auto;
}

.margin-right {
    margin-right: auto;
}

.app-links {
    padding-top: 0.5rem;
    padding-right: 1rem
}

.footer-links {
    display: flex;
    flex: 1;
    padding-top: 0.5rem;
}

.footer-links-ul {
    display: flex;
}

.footer-link {
    padding-right: 1.5rem;
}

.footer-link-a {
    color: var(--text);
    font-weight: 500;
    font-size: 0.9rem;
    transition: fill 0.4s ease, filter 0.4s ease;
}

.footer-end {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
}

.footer-info {
    font-size: 1rem;
    padding: 0.5rem 0px;
    text-align: center;
    font-weight: 500;
}

.footer-portfolio-link {
    font-size: 1rem;
    font-weight: 600;
    color: var(--text);
    transition: fill 0.4s ease, filter 0.4s ease;
}

.footer-portfolio-link:hover {
    filter: brightness(150%) !important;
    color: var(--elements);
}

.footer-link:hover .footer-link-a {
    filter: brightness(150%) !important;
    color: var(--elements);
}

.padding-null {
    padding: 0px;
}

@media screen and (max-width: 700px) {
    .footer {
        padding: 2rem 6vw 2rem 6vw;
        flex-direction: column-reverse;
    }

    .app-icons {
        justify-content: center;
    }

    .app-links {
        padding: 0.6rem;
        padding-bottom: 0.4rem;
    }

    .footer-icons {
        width: 1.5rem;
    }

    .footer-info {
        color: var(--text);
        padding: 0.5rem 0px 0.6rem 0px;
    }

    .footer-links {
        padding-top: 0px;
        justify-content: center;
    }

    .footer-link {
        padding: 0.5rem;
    }

    .footer-link-a {
        font-size: 1rem;
        font-weight: 500;
    }

    .margin-null {
        margin: 0px;
    }
}