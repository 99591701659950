.contact-header {
    padding: 8rem 15vw 4rem 15vw;
    background-color: var(--background);
}

.contact-title {
    font-size: 2.25rem;
    font-weight: 700;
    text-align: center;
}

.contact-header h2 {
    font-weight: 700;
    color: var(--text);
}