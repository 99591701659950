@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700;800&display=swap');

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  width: 100vw;
  overflow-x: hidden;
  background-color: var(--background);
}

html {
  scroll-behavior: smooth;
  font-size: 16px;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  opacity: 1;
  box-sizing: border-box;
  position: relative;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: background-color 0.4s ease-in-out, filter 0.4s ease-in-out, border 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

*:focus {
  outline: 0px;
}

h1,
h2,
h3,
h4,
p,
a {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 2.5rem;
  font-weight: 700;
}

h2 {
  font-size: 2rem;
  font-weight: 500;
  padding: 0.5rem 0rem;
}

h3 {
  font-size: 1.4rem;
}

h4 {
  font-size: 1.2rem;
}

p {
  font-size: 1rem;
  font-weight: 400;
  padding: 0.5rem 0rem;
}

a {
  font-weight: 500;
}

button {
  cursor: pointer;
}

img {
  transition: transform 0.4s ease-in-out;
}

img:hover {
  transform: scale(1.01);
}

@media screen and (max-width: 700px) {
  body {
    width: 100vw;
  }

  html {
    font-size: 15px;
  }

  a {
    font-weight: 600;
  }
}