.share {
    padding: 2rem 10vw 0.5rem 10vw;
    background-color: var(--bg-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.adblocker-warning {
    text-align: center;
    font-size: 0.75rem;
    opacity: 0.7;
    padding: 0px;
    padding-top: 0.5rem;
    color: var(--text);
}

.share-container {
    width: 100%;
    padding: 0.5rem;
    background-color: var(--background);
    border-radius: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.share-btn-container {
    margin-left: auto;
    display: flex;
    padding-right: 1.25rem;
}

.share h3 {
    margin: auto 0px;
    padding-left: 1.25rem;
    color: var(--text);
}

.share button {
    cursor: pointer;
    border: 0px;
    margin: 0.5rem;
    margin-right: 0px;
    padding: 0.5rem;
    border-radius: 50%;
    transition: transform 0.4s ease-in-out;
}

.share button:hover {
    transform: scale(1.075);
}

.share-btn {
    background-color: var(--elements) !important;
}

.share-icon {
    color: var(--white);
}

.twitter {
    background-color: #1DA1F2 !important;
}

.facebook {
    background-color: #0165DE !important;
}

.whatsapp {
    background-color: #38BF4A !important;
}

@media screen and (max-width:700px) {
    .share {
        padding: 1.5rem 6vw 0.5rem 6vw;
        background-color: var(--bg-color);
        display: flex;
        justify-content: center;
    }

    .adblocker-warning {
        display: none;
    }

    .share-container {
        width: 100%;
        padding: 0.2rem;
        background-color: var(--background);
        border-radius: 0.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .share-btn-container {
        margin: auto;
        padding: 0px;
        padding-bottom: 0.2rem;
    }

    .share h3 {
        margin: auto;
        padding: 0px;
        padding-top: 0.5rem;
    }
}