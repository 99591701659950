.blogs {
    color: var(--text);
}

.blog-anim {
    padding: 14.5rem 0px 12rem 0px;
}

.blogs-title-container {
    padding: 8.5rem 15vw 4rem 15vw;
    background-color: var(--background);
}

.blogs-title {
    font-size: 2.25rem;
    font-weight: 700;
}

.blogs-title {
    text-align: center;
}

.blogs-container {
    padding: 5rem 15vw 5rem 15vw;
    background-color: var(--bg-color);
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.blog-element {
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    height: 83vh;
    margin: auto;
    width: 100%;
    background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-color: var(--background);
    transition: transform 0.2s ease-in-out;
    box-shadow: var(--shadow);
}

.blog-details {
    border-radius: 1rem;
    padding: 0.75rem 1.75rem;
    margin-top: auto;
    background-image: linear-gradient(0deg,
            rgba(0, 0, 0, 0.5),
            rgba(247, 247, 247, 0));
}

.blog-date {
    color: var(--white);
    font-weight: 500;
    padding: 0;
    padding-top: 3rem;
}

.blog-title {
    color: var(--white);
    padding-top: 0.25rem;
    font-weight: 700;
}

.blogs-btn-container {
    padding: 0px 0px 4rem 0px;
    display: flex;
    justify-content: center;
    background-color: var(--bg-color);
}

.blog-btn-container {
    padding: 3.5rem 0px 0.5rem 0px;
    display: flex;
}

.blog-btn-link {
    margin-left: auto;
    margin-right: auto;
}

.blog-btn {
    height: max-content;
    font-weight: 600;
    font-size: 1rem;
    padding: 0.75rem 4rem;
    border: 0px;
    background-color: var(--elements);
    color: var(--white);
    padding: 0.75rem 1.75rem;
    border-radius: 2rem;
    transition: opacity 0.2s ease-in-out;
}

.blog-btn::placeholder {
    font-weight: 800;
}

// Animations
.blog-element:hover {
    transform: translateY(-0.5rem);
}

.blog-btn:hover {
    opacity: 0.8;
    cursor: pointer;
}

@media screen and (max-width: 700px) {
    .blog-anim {
        padding: 14.5rem 0px 12rem 0px;
    }

    .blogs-title-container {
        padding: 7rem 6vw 3rem 6vw;
        background-color: var(--background);
        gap: 2rem;
    }

    .blogs-title {
        font-size: 2.25rem;
        font-weight: 700;
    }

    .blogs-container {
        padding: 4rem 6vw 4rem 6vw;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        gap: 2rem;
    }

    .blog-element {
        display: flex;
        flex-direction: column;
        height: 70vh;
        background-size: cover;
        background-position: center bottom;
        width: 100%;
        background-color: var(--background);
    }

    .blog-details {
        padding: 0.5rem 0.75rem;
        margin-top: auto;
        background-image: linear-gradient(0deg,
                rgba(0, 0, 0, 0.4),
                rgba(0, 0, 0, 0));
    }

    .blog-date {
        color: var(--white);
        font-weight: 500;
        padding: 0;
        padding-top: 3rem;
    }

    .blog-title {
        color: var(--white);
        padding-top: 0.25rem;
        font-weight: 800;
    }

    .blog-btn-container {
        padding-top: 3.5rem;
        padding-left: 0px;
        display: flex;
    }

    .blog-btn-link {
        margin-left: auto;
        margin-right: auto;
    }

    .blog-btn {
        height: max-content;
        font-weight: 800;
        font-size: 1rem;
        padding: 0.75rem 2rem;
        border: 0px;
        background-color: var(--elements);
        color: var(--white);
        border-radius: 2.5rem;
    }

    .blog-btn::placeholder {
        font-weight: 800;
    }
}