.app-loader {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.app {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
}