.loader-container {
    display: flex;
}

.loading-animation {
    z-index: 10;
    width: 100vw;
    height: 100vh;
    display: flex;
    background-color: var(--elements);
    justify-content: center;
}