.latest-blogs {
  padding: 3rem 20vw;
  background-color: var(--bg-color);
}

.latest-title {
  padding: 0;
  font-style: italic;
  font-weight: 800;
  color: var(--text);
  text-transform: uppercase;
  font-size: 2rem;
}

.latest-blogs-container {
  margin-top: 1.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

// Loading Anim
.loading-anim {
  padding-top: 10rem;
  padding-bottom: 10rem;
  opacity: 1;
  display: flex;
  background-color: var(--elements);
  justify-content: center;
}

@media screen and (max-width: 700px) {
  .latest-title {
    font-size: 1.5rem;
  }

  .latest-blogs {
    padding: 3rem 6vw;
  }

  .latest-blogs-container {
    display: grid;
    margin-top: 1.1rem;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
}